<template>
  <div>
    <p class="timer h--medium">{{ minutes }} : {{ seconds }}</p>
  </div>
</template>

<script>
export default {
  props: {
    // A date string in the format of YYYY-MM-DDTHH:MM:SS
    endTime: {
      type: String,
      required: true,
    },
    currentTime: {
      type: Number,
      default: () => Date.now(),
    },
  },
  data() {
    return {
      totalSeconds: 0,
      minutes: 0,
      seconds: 0,
      timer: null,
    }
  },
  mounted() {
    this.startTimer()
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  methods: {
    startTimer() {
      this.calculateTotalSeconds()
      this.timer = setInterval(this.updateTimer, 1000)
    },
    calculateTotalSeconds() {
      const endTimeMilliseconds = new Date(this.endTime).getTime()
      const currentTimeMilliseconds = this.currentTime
      this.totalSeconds = Math.max(
        0,
        Math.floor((endTimeMilliseconds - currentTimeMilliseconds) / 1000)
      )
      this.calculateTime()
    },
    updateTimer() {
      if (this.totalSeconds > 0) {
        this.totalSeconds--
        this.calculateTime()
      } else {
        this.stopTimer()
      }
    },
    calculateTime() {
      this.minutes = Math.floor(this.totalSeconds / 60)
      this.seconds = this.totalSeconds % 60
    },
    stopTimer() {
      clearInterval(this.timer)
    },
  },
}
</script>
