var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (_vm.products && _vm.products.length) ||
    (_vm.items && _vm.items.length) ||
    (_vm.$slots.products && _vm.$slots.products.length)
  )?_c('div',{staticClass:"product-list-swipeable",class:[
    _vm.mobileMargin ? 'mm--' + _vm.mobileMargin : '',
    _vm.desktopMargin ? 'dm--' + _vm.desktopMargin : '',
  ]},[_c('div',{staticClass:"list-header",class:[
      _vm.mobilePadding ? 'mp--' + _vm.mobilePadding : '',
      _vm.desktopPadding ? 'dp--' + _vm.desktopPadding : '',
      _vm.isAfterCheckout ? 'after-checkout' : '',
    ]},[_vm._t("headline"),_vm._v(" "),(!_vm.isAfterCheckout)?_c('div',_vm._l((_vm.headlines),function(headline,index){return _c('Headline',_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(headline),expression:"headline"}],key:'headline' + index},'Headline',headline,false))}),1):_vm._e(),_vm._v(" "),(_vm.upsell && _vm.isAfterCheckout)?_c('div',{staticClass:"upsell-offer"},[_vm._l((_vm.upsellOffer),function(block,index){return _c('div',{key:index},[_c('TextBlock',{key:'textblock' + index,attrs:{"text":block.text,"headlines":block.headlines,"text-align":block.textAlign}})],1)}),_vm._v(" "),_c('Timer',{attrs:{"end-time":_vm.timerEndTime}})],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"nav",class:{
        show:
          !_vm.hideNav &&
          ((_vm.products && _vm.products.length > 2) ||
            (_vm.items && _vm.items.length > 2) ||
            (_vm.$slots.products && _vm.$slots.products.length > 2)),
      }},[_c('span',{ref:"prev",staticClass:"arrow prev",on:{"click":_vm.slideToPrev}},[_c('span',{staticClass:"icon"},[_c('ChevronLeftLarge')],1)]),_vm._v(" "),_c('span',{ref:"next",staticClass:"arrow next",on:{"click":_vm.slideToNext}},[_c('span',{staticClass:"icon"},[_c('ChevronRightLarge')],1)])])],2),_vm._v(" "),_c('div',{ref:"slider",staticClass:"products",class:[
      _vm.mobilePadding ? 'mp--' + _vm.mobilePadding : '',
      _vm.desktopPadding ? 'dp--' + _vm.desktopPadding : '',
      _vm.mobileGridGutter ? 'm-gutter--' + _vm.mobileGridGutter : '',
      _vm.desktopGridGutter ? `d-gutter--${_vm.desktopGridGutter}` : '',
      `m-cols--${_vm.mobileColumns}`,
    ],on:{"scroll":_vm.scrolling}},[_vm._t("products"),_vm._v(" "),(_vm.productCards.length)?[_vm._l((_vm.productCards),function(product,index){return [(_vm.upsell)?_c('ProductCardUpsell',_vm._b({key:index + _vm._uid,attrs:{"cross-sell":_vm.crossSell,"product-id":product.productId || product.product,"mobile-display-size":_vm.mobileSize,"desktop-display-size":_vm.desktopSize}},'ProductCardUpsell',product,false)):(product.component === 'ProductCardCentra')?_c('ProductCardCentra',_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(product),expression:"product"}],key:index,staticStyle:{"padding-bottom":"16px"},attrs:{"position":index,"mobile-display-size":_vm.mobileSize,"desktop-display-size":_vm.desktopSize,"show-cart-icon":_vm.enableAddToCart}},'ProductCardCentra',product,false)):(_vm.products && _vm.products.length)?_c('ProductCard',{key:_vm.categoryUri + '-' + product.product,attrs:{"product":product,"position":index + 1,"index":index,"mobile-display-size":_vm.mobileSize,"desktop-display-size":_vm.desktopSize,"show-cart-icon":_vm.enableAddToCart}}):_vm._e()]})]:_vm._e()],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }